.App {
  display: flex;
  height: 100vh;
}

.App-header {
  width: 20%; /* Control the width of the left column */
  display: flex;
  flex-direction: column;
  padding: 10px; /* Adds scroll to the column if content is too long */
}


.App-header-2 {
  width: 75px; /* Control the width of the left column */
  display: flex;
  flex-direction: column;
  padding: 10px;
  visibility: hidden;
  overflow-y: auto; /* Adds scroll to the column if content is too long */
}

input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.menu-shim {
  margin-left: 75px;
  margin-top: 65px;
  background-color: #eeeeee;
}

.menu-shim-2 {
  margin-left: 0px;
  margin-top: 65px;
  background-color: #eeeeee;
}

.button {
  background-color: #04AA6D; /* Green */
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button1 {
  background-color: white; 
  color: black; 
  border: 2px solid #04AA6D;
}

.main-search-bar {
  position: absolute;
  top: 130px;
  left: 120px;
  z-index:1000;
  display: flex;
  background: white;
  padding: 5px;
  gap: 5px;
}

.main-button-bar {
  position: absolute;
  top: 70px;
  left: 120px;
  z-index:1000;
  display: flex;
  background: white;
  padding: 5px;
  gap: 5px;
}

.main-pin-drop {
  position: absolute;
  top: 210PX;
  left: 120px;
  z-index:1000;
  display: flex;
  background: white;
  padding: 10px;
  gap: 5px;
  overflow-y: auto;
  max-height: 60%;
}

.create-new-lead {
  position: absolute;
  top: 220PX;
  left: 120px;
  z-index:1000;
  display: flex;
  background: white;
  padding: 10px;
  gap: 5px;
  overflow-y: auto;
  max-height: 60%;
}


.pipeline-panel {
  position: absolute;
  top: 220PX;
  left: 120px;
  z-index:1000;
  display: flex;
  background: white;
  padding: 10px;
  gap: 5px;
  overflow-y: auto;
  max-height: 60%;
}

.active-deal-panel {
  position: absolute;
  top: 220PX;
  left: 120px;
  z-index:1000;
  display: flex;
  background: white;
  padding: 10px;
  gap: 5px;
  overflow-y: auto;
  max-height: 60%;
}


.button1:hover {
  background-color: #04AA6D;
  color: white;
}


.map-container {
  width: 100%; /* Remaining width for the map */
  height: 95vh; /* Full viewport height */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.server-data {
  font-size: medium;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* LoginPage.css */

.root {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.paper {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.title {
  margin-bottom: 16px;
}

.message {
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.button {
  margin-top: 16px;
}